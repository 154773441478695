import { Layout } from "@/components/Layout";
import { useDeviceQuery } from "@/utils";
import { Container, Title, Text, rem, Stack, Center } from "@mantine/core";
import { GetStaticProps } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";

export default function Error404(): JSX.Element {
  const { t } = useTranslation();
  const [isMobile] = useDeviceQuery();

  return (
    <>
      <Head>
        <title>{t("meta:404.title")}</title>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta name="robots" content="noindex, nofollow" />
        <link href="/favicon.ico" rel="icon" />
      </Head>
      <Layout>
        <Container ta="center">
          <Center h={400}>
          <Stack>
            <Title order={2} size={rem(80)}>
              {t("error:404.code")}
            </Title>
            <Title order={3}>{t("error:404.title")}</Title>
            <Text>{t("error:404.description")}</Text>
          </Stack>
          </Center>
        </Container>
      </Layout>
    </>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const translations = await serverSideTranslations(locale ?? "en", [
    "meta",
    "common",
    "error",
  ]);

  return {
    props: {
      ...translations,
    },
  };
};
